import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'

const Menu = (props) => (
    <nav id="menu">
        <div className="inner">
            <ul className="links">
                <li><Link onClick={props.onToggleMenu} to="/">Домой</Link></li>
                <li><Link onClick={props.onToggleMenu} to="/bytovka">Бытовки</Link></li>
                <li><Link onClick={props.onToggleMenu} to="/bytovka">Балконы</Link></li>
                <li><Link onClick={props.onToggleMenu} to="/bytovka">Еще...</Link></li>
            </ul>
            <ul className="actions vertical">
                <li><a href="tel:+79898373166" className="button special fit">Позвонить</a></li>
            </ul>
        </div>
        <a className="close" onClick={props.onToggleMenu} href="javascript:;">Закрыть</a>
    </nav>
)

Menu.propTypes = {
    onToggleMenu: PropTypes.func
}

export default Menu
